<template>
    <div class="footer">
        <div class="copy-right">技术支持：抵御者网络科技</div>
        <div class="icp-police">
            <a class="icp" href="http://beian.miit.gov.cn/" target="_blank"><span>豫ICP备2021027095号</span></a>
            <a class="police" target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41078102000150">
                <!-- <img src="/police_bk.png" /> -->
                <span>豫公网安备 41078102000150号</span>
            </a>
        </div>
        <div class="contact">
            <a class="contact-item" href="javascript:;">
                <el-popover placement="top" trigger="hover">
                    <el-image :src="wechatImg" style="width: 150px; height: 150px" fill
                        :preview-src-list="[wechatImg]"></el-image>
                    <img src="../../assets/imgs/contact_wechat.png" alt="" slot="reference">
                </el-popover>
            </a>
            <a class="contact-item" href="javascript:;">
                <el-popover placement="top" trigger="hover">
                    <el-image :src="qqImg" style="width: 150px; height: 150px" fill :preview-src-list="[qqImg]"></el-image>
                    <img src="../../assets/imgs/contact_qq.png" alt="" slot="reference">
                </el-popover>
            </a>
            <a class="contact-item" href="mailto:864823609@qq.com">
                <el-popover placement="top" trigger="hover">
                    <span>864823609@qq.com</span>
                    <img src="../../assets/imgs/contact_email.png" alt="" slot="reference">
                </el-popover>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    computed: {
        assetsUrl() {
            return this.$store.state.assetsUrl;
        },
        qqImg() { return this.assetsUrl + "/assets/contact/qq_contact_qrcode.jpg" },
        wechatImg() { return this.assetsUrl + "/assets/contact/wx_contact_qrcode.jpg" },
    }
}
</script>

<style lang="scss" scoped>
.footer {
    background-color: #1d1f22;
    width: 100%;
    text-align: center;
    color: #eaeaea;
    font-size: 12px;
    padding: 20px 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'Times New Roman', Times, serif;

    a {
        color: #eaeaea;

        &:hover {
            text-decoration: underline;
            color: #fff;
        }
    }

    .copy-right {
        line-height: 32px;
        margin-bottom: 10px;
        padding: 0 10px;
    }

    .icp-police {
        word-break: break-all;
        line-height: 32px;
        margin-bottom: 10px;

        a {
            padding: 10px;
            color: #eaeaea;
            display: inline-block;

            img {
                margin-right: 5px;
                vertical-align: middle;
                width: 14px;
                height: 14px;
            }

            span {
                vertical-align: middle;
            }
        }
    }

    .contact {
        margin-bottom: 10px;
        padding: 0 10px;

        .contact-item {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-left: 10px;
            // background-color: #fff;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: block;
            }

            &:nth-of-type(1) {
                margin-left: 0;
            }
        }
    }
}
</style>